import { render, staticRenderFns } from "./EquipmentCreate.vue?vue&type=template&id=b337af4c&scoped=true&"
import script from "./EquipmentCreate.vue?vue&type=script&lang=js&"
export * from "./EquipmentCreate.vue?vue&type=script&lang=js&"
import style0 from "./EquipmentCreate.vue?vue&type=style&index=0&id=b337af4c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b337af4c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VContainer,VFileInput,VFlex,VForm,VTextField,VTextarea})
